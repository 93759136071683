import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

/** Apollo */
import ApolloClient from 'apollo-client';
import { ApolloProvider } from 'react-apollo';
import { createUploadLink as CreateUploadLink } from 'apollo-upload-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
/** layout */
import Footer from './components/layout/Footer';

/** Actual Pages */
import ErrorBoundary from './components/error-boundary/ErrorBoundary';
import Fallback from './components/error-boundary/Fallback';

/** libs */
import routes from './lib/constants/routes';
import Dashboard from './view/Dashboard/Dashobard';


const { localStorage } = window;

let apiUrl = 'https://phplaravel-1095138-3835803.cloudwaysapps.com/graphql'

export const appUri = apiUrl
export const urlApi = 'https://phplaravel-1095138-3835803.cloudwaysapps.com'

const link = new CreateUploadLink({
  uri: apiUrl,
  headers: {
    Authorization: localStorage.getItem('auth_token')
      ? `Bearer ${localStorage.getItem('auth_token')}`
      : ''
  }
});



const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});


const App = () => {
  //Saving user data to the local storage
  const userData = useSelector(state => state.auth.user);

  return (
    <ApolloProvider client={client} fetchPolicy="network-only">
      <Router>
          {userData ? (
            <>
              <div className="container-scroller">
                  <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                      <ErrorBoundary>
                        <Switch>
                          <Route
                            exact
                            path={routes.home.index}
                            render={routeProps => (
                              <Dashboard />
                            )}
                          />
                          <Route exact path="/">
                            <Redirect to={routes.home.index} />
                          </Route>
                          <Fallback is404={true} />
                        </Switch>
                      </ErrorBoundary>
                    <Footer />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="spinner absolute" />
          )}
      </Router>
    </ApolloProvider>
  );
};
export default App;
