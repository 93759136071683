export const HIDE_SIDEBAR = 'HIDE_SIDEBAR';
export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
export const MINIMIZE_SIDEBAR = 'MINIMIZE_SIDEBAR';
export const MAXIMIZE_SIDEBAR = 'MAXIMIZE_SIDEBAR';

const initialState = {
  display: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case HIDE_SIDEBAR: {
      return {
        display: !state.display
      };
    }

    default: {
      return { ...state };
    }
  }
};
