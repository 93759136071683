import React from 'react';
import { NavLink } from 'react-router-dom';

import './Fallback.scss';

const Fallback = ({ clearError, is404 }) => {
  return (
    <div className="fallback">
      <div className="fallback__wrapper">
        <div className="fallback__logo-wrapper">
          <img className="fallback__logo" src="/assets/images/logo.png" alt="" />
        </div>
        <p className="fallback__primary-text">
          {is404 ? 'Page was not found' : 'Something went wrong!'}
        </p>
        <p className="fallback__primary-text">{`(${is404 ? '404' : 'Internal Server'} Error)`}</p>
        <p className="fallback__secondary-text">
          {is404 ? '' : 'Please contact our support or refresh the page'}
        </p>
        <NavLink className="btn btn-primary" to="/" onClick={clearError}>
          Go back home
        </NavLink>
      </div>
    </div>
  );
};

export default Fallback;
