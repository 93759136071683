import React from 'react';

import Fallback from './Fallback';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error) {
    console.dir(error);
    this.setState({ hasError: true });
  }

  clearError = () => {
    this.setState({ hasError: false });
  };

  render() {
    if (this.state.hasError) {
      return <Fallback clearError={this.clearError} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
