import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, Redirect } from 'react-router-dom';
import { urlApi } from '../../App';
import { QrReader } from 'react-qr-reader';

import './style.css'

/** Components */

const Dashboard = ({  }) => {
  const history = useHistory();
  const location = useLocation();
  const [ticket, setTicket] = useState(0)
  const [data, setData] = useState(null);


  useEffect(() => {
    if(data){
      Axios.post(`${urlApi}/api/get-ticket-info/${data}`).then(res => {
        setTicket(res.data)

        Axios.post(`${urlApi}/api/get-ticket-info/${data}/used`)
      })
    }
  }, [data])

  const handleClick = () => {
    setData(null)
    setTicket(null)
  }


  return (
    <>
      <header className='px-3 text-center'>
        <img src="https://www.eventyapp.io/assets/images/icon.png" style={{ width: '40px' }} alt="" />
        <h3 style={{ marginTop: '10px' }}>Eventy App</h3>
      </header>

      <div className="qrReader px-3 text-center mt-2" >
        { !data && (
          <QrReader
            containerStyle={{width: '100%' }}
            constraints={{ facingMode: { exact: 'environment' } }}
            
            onResult={(result, error) => {
              if (!!result) {
                setData(result?.text);
              }

              if (!!error) {
                console.info(error);
              }
            }}
            style={{ width: '100%' }}
          />

        )}

        { data &&  (
          <div className="dataWrapper">
            <div className="eventImage">
              <img src={ticket?.event?.image} style={{width: '100%'}} alt="" />
            </div>
            <div className="eventTitle my-3">
              { !ticket && <p className="skeleton"></p> }
              <p style={{ fontWeight: 'bold' }}>{ticket?.event?.title}</p>
            </div>
            <div className="ticketStatus mb-5">
              { !ticket && <p className="skeleton"></p> }
              { ticket && ticket.ticket && (
                <>
                  <p style={{ fontWeight: 'bold', marginBottom: '10px' }}>{ ticket.ticket.is_vip == 0 ? '' : '⚜️ VIP ⚜️'}</p>
                  <p style={{ fontWeight: 'bold', marginBottom: '10px' }}>სტატუსი: { ticket.is_used == 0 ? 'აქტიური ✅' : 'გამოყენებული ❌'}</p>
                  <p style={{ fontWeight: 'bold' }}>ბილეთის #: { ticket.ticket.ticket_no }</p>
                </>
              )}
            </div>
          </div>
        )}

        <button className='btn btn-primary mt-3' onClick={handleClick}>ახალი სკანერი</button>
      
      </div>
    </>
  );
};

export default Dashboard;
