export const DRAW = "DRAW";
export const CLEAN = "CLEAN";
 
const initialState = {
    draw    : false,
    page    : 1,
    first   : 10,
    keyword : ''
};

export default (state = initialState , action) => {
    
    switch(action.type){
        case DRAW: {
            return {
                draw    : action.payload,
                page    : action.payload.page,
                first   : action.payload.first,
                keyword : action.payload.keyword
            };
        }
        case CLEAN: {
            
            return {
                draw:false,
                page:1,
                first:10,
                keyword:''
            }
        }

        default: {
            return { ...state }
        }
    }
}


