import { combineReducers } from 'redux';

/** reducers */
import ui from './UiReducer';
import notification from './Notification';
import auth from './auth';
import sideBar from './SideBar';


const rootReducer = combineReducers({
  ui,
  notification,
  auth,
  sideBar,
});

export default rootReducer;
 